import { mediacoreApi } from '../mediacoreApi'
import { Action } from 'types'
const pushExtended = mediacoreApi.injectEndpoints({
  endpoints: (builder) => ({
    getPush: builder.query<Action[], { page?: string; data_id?: string }>({
      query: ({ page = '', data_id = '' }) =>
        `push/acciones/${process.env.GATSBY_CLIENTID}/activas/${page ? '?page=' + page : ''}${
          data_id ? '&data_id=' + data_id : ''
        }`,
    }),
  }),
  overrideExisting: false,
})

export const { useGetPushQuery } = pushExtended

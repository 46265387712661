import { Job } from 'types'
import { mediacoreApi } from '../mediacoreApi'

const serializeData = (data: any) => {
  const formData = new FormData()
  for (let key in data) {
    if (key === 'files') Array.from(data[key]).forEach((file: any) => formData.append(key, file))
    else
      formData.append(
        key,
        data[key] instanceof Blob || typeof data[key] === 'string'
          ? data[key]
          : JSON.stringify(data[key]),
      )
  }

  return formData
}

const is_compiler = process.env.GATSBY_IS_SITE_COMPILER === 'true'
const TENANTID = process.env.GATSBY_TENANTID

const jobsExtended = mediacoreApi.injectEndpoints({
  endpoints: (builder) => ({
    getJobs: builder.query<Job[], { site: string }>({
      query: ({ site = '' }) => ({
        url: `jobs/posiciones/activas/?client=${process.env.GATSBY_CLIENTID}${
          is_compiler ? '&site=' + site : ''
        }`,
      }),
    }),
    createApplicationJob: builder.mutation({
      query: ({ data, site = '' }) => ({
        url: `jobs/aplicaciones/aplicar/?client=${process.env.GATSBY_CLIENTID}&site=${
          is_compiler ? site : TENANTID
        }`,
        method: 'POST',
        body: serializeData(data),
      }),
    }),
  }),
  overrideExisting: false,
})

export const { useGetJobsQuery, useCreateApplicationJobMutation } = jobsExtended

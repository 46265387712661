import { useGetPropertyQuery as useGetPropertyQueryTokko } from '@apis/middlewareTokkoApi/properties'
import { useGetPropertyQuery as useGetPropertyQueryMediacore } from '@apis/mediacore/properties'

function useQueryProperty(id?: string, tokkoKey?: string) {
  const skip = !id

  const {
    data,
    data: allPropertyData,
    isLoading,
    isError,
    isFetching,
  } = tokkoKey
    ? useGetPropertyQueryTokko({ API_KEY: tokkoKey, ID: id }, { skip })
    : useGetPropertyQueryMediacore(
        {
          CLIENT_ID: process.env.GATSBY_CLIENTID,
          PROP_ID: id,
        },
        { skip },
      )

  return { data, allPropertyData, isLoading, isError, isFetching }
}

export default useQueryProperty

import {
  createApi,
  fetchBaseQuery,
} from "@reduxjs/toolkit/query/react";

export const mediacoreApi = createApi({
  reducerPath: "mediacoreApi",
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.GATSBY_APISERVER,
  }),
  tagTypes: [],
  endpoints: () => ({
    // getBlogSummary: builder.query<{ detail: string }, { client: number }>({
    //   query: ({ client }: { client: number }) => ({
    //     url: "blog/get_data_summary/",
    //     params: {
    //       client,
    //     },
    //   }),
    // }),
  }),
});

// export const { usePrefetch, useGetBlogSummaryQuery } = mediacoreApi;

// Configure RTK Query following official docs, see https://redux-toolkit.js.org/rtk-query/usage-with-typescript

// TODO: Split file for maintanability purposes.

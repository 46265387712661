import { orderNovelties } from '@helpers/helper.novelty'
import { mediacoreApi } from '../mediacoreApi'
import { ResponseSummaryBlog } from 'types'

const is_compiler = process.env.GATSBY_IS_SITE_COMPILER === 'true'
const TENANTID = process.env.GATSBY_TENANTID

const blogExtended = mediacoreApi.injectEndpoints({
  endpoints: (builder) => ({
    getBlogs: builder.query<ResponseSummaryBlog, { site: string }>({
      query: ({ site = '' }) => ({
        url: `blog/novedades/get_data_summary/?client=${process.env.GATSBY_CLIENTID}${
          is_compiler ? '&site=' + site : ''
        }`,
      }),
      transformResponse: (data: ResponseSummaryBlog, _headers, extraOptions) => {
        const { site } = extraOptions as { site: string }
        return { news: orderNovelties(data.news, is_compiler ? site : TENANTID), tags: data.tags }
      },
    }),
    getDetailBlog: builder.query({
      query: ({ BLOG_ID }) =>
        `blog/novedades/${BLOG_ID}/get_data_detail/?client=${process.env.GATSBY_CLIENTID}`,
    }),
  }),
  overrideExisting: false,
})

export const { useGetBlogsQuery, useGetDetailBlogQuery } = blogExtended

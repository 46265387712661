import React, { useEffect, useState } from 'react'
import { useLocation } from '@reach/router'
import { informCustomEvent } from '@helpers/helper.analytics'
import { getCodes, getQueryEventTracker } from '@helpers/helper.actions'
import { ModuleActionProps } from 'types'
import { useCreateApplicationMutation as useSendTrackerEvent } from '@apis/mediacore/tracker'

const SquarePopup = ({
  align,
  flat,
  linkTo,
  showOn,
  image,
  utmValue,
  property,
  development,
  action,
  page,
}: ModuleActionProps) => {
  const location = useLocation()
  const pathname = location.pathname.replaceAll('/', '')
  const [sendTrackerEvent] = useSendTrackerEvent()

  const [isOpen, setIsOpen] = useState(false)

  // Timeout to show the component
  useEffect(() => {
    if (checkPage() && !isOpen) {
      if (
        (action.section === 'Detalle de desarrollo' && development) ||
        (action.section === 'Detalle de propiedad' && property) ||
        (action.section !== 'Detalle de desarrollo' && action.section !== 'Detalle de propiedad')
      ) {
        informCustomEvent(
          'SHOW_' +
            page.id +
            '_' +
            (property && action.section === 'Detalle de propiedad'
              ? property.id.toString()
              : development && action.section === 'Detalle de desarrollo'
              ? development.id.toString()
              : '0') +
            '_' +
            action.id,
        )
        sendTrackerEvent(
          getQueryEventTracker({
            page: page,
            action: action,
            type: 'SHOW',
            data_id:
              property && action.section === 'Detalle de propiedad'
                ? property.id.toString()
                : development && action.section === 'Detalle de desarrollo'
                ? development.id.toString()
                : '0',
            url: location.href,
            extra_title:
              action.section === 'Detalle de propiedad' && property
                ? property?.address
                : development && action.section === 'Detalle de desarrollo'
                ? development.name
                : '',
          }),
        )
        setTimeout(() => {
          if ((flat && screen.width > 992) || !flat)
            document.getElementById('squarePopUpBtn')?.click()
          setIsOpen(true)
        }, 2000)
      }
    }
  }, [pathname, property, development, location])

  // Check align
  const checkAlign = () => {
    return align?.toLowerCase() === 'left'
      ? 'align-left'
      : align?.toLowerCase() === 'right'
      ? 'align-right'
      : align?.toLowerCase() === 'center'
      ? 'align-center'
      : ''
  }

  // Limit the height
  const checkFlat = () => {
    return flat ? 'flat-width d-none d-lg-flex' : ''
  }

  // Go to link, disable popup
  const goTo = () => {
    document.getElementById('closePopup')?.click()
    if (linkTo) {
      window.open(linkTo + utmValue, '_blank', 'noopener,noreferrer')
      informCustomEvent(
        'PUSH_' +
          page.id +
          '_' +
          (property && action.section === 'Detalle de propiedad'
            ? property.id.toString()
            : development && action.section === 'Detalle de desarrollo'
            ? development.id.toString()
            : '0') +
          '_' +
          action.id,
      )
      sendTrackerEvent(
        getQueryEventTracker({
          page: page,
          action: action,
          type: 'PUSH',
          data_id:
            property && action.section === 'Detalle de propiedad'
              ? property.id.toString()
              : development && action.section === 'Detalle de desarrollo'
              ? development.id.toString()
              : '0',
          url: location.href,
          extra_title:
            action.section === 'Detalle de propiedad' && property
              ? property?.address
              : development && action.section === 'Detalle de desarrollo'
              ? development.name
              : '',
        }),
      )
    }
  }

  // Check where shows
  const checkPage = () => {
    return true
    // if(properties?.length > 0 && property?.id && properties?.find(prop => prop?.toString() === property?.id?.toString()) ||
    // developments?.length > 0 && development? && developments?.find(dev => dev?.toString() === development.id?.toString())){
    // if (developments?.length! > 0 || properties?.length! > 0) {
    //   //Si es para una ficha en particular
    //   if (
    //     existSquarePopDetail(property?.toString()!) ||
    //     existSquarePopDetail(development?.toString()!)
    //   ) {
    //     if (
    //       properties?.find((prop) => prop?.toString() === property?.toString()) ||
    //       developments?.find((dev) => dev?.toString() === development?.toString())
    //     ) {
    //       return true
    //     }
    //   }
    //   return false
    // } else {
    //   if (pathname === '' && showOn.toLowerCase() === 'inicio') {
    //     return true
    //   }
    //   if (pathname === 'quienes-somos' && showOn.toLowerCase() === 'nosotros') {
    //     return true
    //   }
    //   if (pathname === 'servicios' && showOn.toLowerCase() === 'tasaciones') {
    //     return true
    //   }
    //   if (
    //     pathname.toLowerCase().includes('emprendimiento') &&
    //     pathname.length > 18 &&
    //     showOn.toLowerCase() === 'ficha de emprendimiento' &&
    //     !existSquarePopDetail(development?.toString()!)
    //   ) {
    //     return true
    //   }
    //   if (
    //     pathname.toLowerCase().includes('propiedad') &&
    //     showOn.toLowerCase() === 'ficha de la propiedad' &&
    //     !existSquarePopDetail(property?.toString()!)
    //   ) {
    //     return true
    //   }
    //   if (pathname.toLowerCase() === 'venta' && showOn.toLowerCase() === 'resultados venta') {
    //     return true
    //   }
    //   if (pathname.toLowerCase() === 'alquiler' && showOn.toLowerCase() === 'resultados alquiler') {
    //     return true
    //   }
    //   if (
    //     pathname.toLowerCase() === 'temporario' &&
    //     showOn.toLowerCase() === 'resultados alquiler temporario'
    //   ) {
    //     return true
    //   }
    //   return Array.isArray(showOn)
    //     ? showOn.toLowerCase().includes(pathname.toLowerCase())
    //     : pathname.toLowerCase() === showOn.toLowerCase() || false
    // }
  }

  const existSquarePopDetail = (id: string) => {
    // const squarePopUps = [
    //   ...getActionByType(allPushData, getCodeFromType('PopUp Vertical')),
    //   ...getActionByType(allPushData, getCodeFromType('PopUp Cuadrado')),
    //   ...getActionByType(allPushData, getCodeFromType('PopUp Timer')),
    //   ...getActionByType(allPushData, getCodeFromType('PopUp Panoramico')),
    // ]
    // if (id) {
    //   for (const singleSquare of squarePopUps) {
    //     for (const dev of singleSquare.developments) {
    //       if (dev.toString() === id.toString()) {
    //         return true
    //       }
    //     }
    //     for (const prop of singleSquare.properties) {
    //       if (prop.toString() === id.toString()) {
    //         return true
    //       }
    //     }
    //   }
    //   return false
    // } else {
    //   return false
    // }
  }

  return (
    <>
      {checkPage() ? (
        <div className="article">
          <button
            type="button"
            id="squarePopUpBtn"
            data-bs-toggle="modal"
            data-bs-target="#squarePopUp"
          ></button>
          <div
            className="modal fade"
            id="squarePopUp"
            tabIndex={-1}
            aria-hidden="true"
          >
            <div className={`modal-dialog ${checkAlign()} ${checkFlat()}`}>
              <div
                className={`modal-content ${checkFlat()}`}
                style={{ backgroundImage: 'url(' + image + ')' }}
              >
                <div
                  className="modal-header"
                  onClick={() => document.getElementById('closePopup')?.click()}
                >
                  <i
                    className="icon-plus"
                    role="button"
                    id="closePopup"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                    onClick={() => document.getElementById('closePopup')?.click()}
                  ></i>
                </div>
                <div
                  className={`modal-body ${checkFlat()}`}
                  onClick={() => goTo()}
                ></div>
              </div>
            </div>
          </div>
        </div>
      ) : null}
    </>
  )
}

export default SquarePopup

// Component usage
// <SquarePopup -> Square aligned center by default
//      align={'right' || 'left' || 'center'} -> Set the align for vertical banner
//      flat={true} -> Square flat with max-height
//      linkTo={'/emprendimientos/'} -> Set the link of image
//      showOn={'servicios' | ['venta','alquiler'] } /> -> Set route where shows the banner

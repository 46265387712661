import { useLocation } from '@reach/router'
import React, { useEffect } from 'react'
import { informCustomEvent } from '@helpers/helper.analytics'
import {
  getActionByType,
  getCodeFromType,
  getCodes,
  getQueryEventTracker,
} from '@helpers/helper.actions'
import { useGetPushQuery } from '@apis/mediacore/push'
import { ModuleActionProps } from 'types'
import { useCreateApplicationMutation as useSendTrackerEvent } from '@apis/mediacore/tracker'
const FlapBtn = ({
  alignment = 'left',
  linkTo,
  linkTxt,
  utmValue,
  action,
  property,
  page,
  showOn,
  development, // developments,
}: ModuleActionProps) => {
  const location = useLocation()
  const pathname = location.pathname.replaceAll('/', '')

  const [sendTrackerEvent] = useSendTrackerEvent()

  useEffect(() => {
    if (
      (action.section === 'Detalle de desarrollo' && development) ||
      (action.section === 'Detalle de propiedad' && property) ||
      (action.section !== 'Detalle de desarrollo' && action.section !== 'Detalle de propiedad')
    ) {
      informCustomEvent(
        'SHOW_' +
          page.id +
          '_' +
          (property && action.section === 'Detalle de propiedad'
            ? property.id.toString()
            : development && action.section === 'Detalle de desarrollo'
            ? development.id.toString()
            : '0') +
          '_' +
          action.id,
      )
      sendTrackerEvent(
        getQueryEventTracker({
          page: page,
          action: action,
          type: 'SHOW',
          data_id:
            property && action.section === 'Detalle de propiedad'
              ? property.id.toString()
              : development && action.section === 'Detalle de desarrollo'
              ? development.id.toString()
              : '0',
          url: location.href,
          extra_title:
            action.section === 'Detalle de propiedad' && property
              ? property?.address
              : development && action.section === 'Detalle de desarrollo'
              ? development.name
              : '',
        }),
      )
    }
  }, [development, property])

  return (
    // checkPage() && (
    <div
      id="flap-btn"
      className={`fixed-btn-wrapper ${alignment}`}
    >
      <a
        href={linkTo + utmValue}
        onClick={() => {
          informCustomEvent(
            'PUSH_' +
              page.id +
              '_' +
              (property && action.section === 'Detalle de propiedad'
                ? property.id.toString()
                : development && action.section === 'Detalle de desarrollo'
                ? development.id.toString()
                : '0') +
              '_' +
              action.id,
          )
          sendTrackerEvent(
            getQueryEventTracker({
              page: page,
              action: action,
              type: 'PUSH',
              data_id:
                property && action.section === 'Detalle de propiedad'
                  ? property.id.toString()
                  : development && action.section === 'Detalle de desarrollo'
                  ? development.id.toString()
                  : '0',
              url: location.href,
              extra_title:
                action.section === 'Detalle de propiedad' && property
                  ? property?.address
                  : development && action.section === 'Detalle de desarrollo'
                  ? development.name
                  : '',
            }),
          )
        }}
        className="btn-link"
        target="_blank"
      >
        {linkTxt}
      </a>
    </div>
  )
}

export default FlapBtn

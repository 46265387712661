import { createSlice } from '@reduxjs/toolkit'
import { StatePropertiesSlice } from 'types'

export const propertiesSlice = createSlice({
  name: 'properties',
  initialState: {
    searchHome: {
      operation: 'venta',
      location: [],
      type: [],
      address: '',
    },
    filters: {
      location: [],
      type: [],
      operation: '',
      environments: '',
      bedrooms: '',
      age: '',
      address: '',
      producer_id: '',
      price: {
        type: '',
        priceMin: '',
        priceMax: '',
        is_off: false,
      },
      surface: {
        type: '',
        surfaceMin: '',
        surfaceMax: '',
      },
      environments_types: [],
      generals: [],
      services: [],
      specials: [],
    },
    updaterFavorites: false,
    offset: 0,
    coordinates: [],
    polygonCoordinates: [],
    orders: {
      order: 'desc',
      order_by: 'id',
    },
    selected: 0,
    viewId: 0,
    map: null,
    focusPropertyId: null,
  } as StatePropertiesSlice,
  reducers: {
    updateSearcherHome: {
      reducer(state, action) {
        state.searchHome = action.payload
      },
      prepare(searchHome) {
        return {
          payload: searchHome,
          meta: '',
          error: '',
        }
      },
    },
    reduxUpdateFilters: {
      reducer(state, action) {
        state.filters = action.payload
      },
      prepare(_filters, toggleOperation = '') {
        if (toggleOperation) {
          if (toggleOperation === 'ALQUILER TEMP.') {
            _filters.operation = 'alquiler-temporario'
          } else {
            _filters.operation = toggleOperation
          }
        }
        return {
          payload: _filters,
          meta: '',
          error: '',
        }
      },
    },
    reduxUpdateOrderBy: {
      reducer(state, action) {
        state.orders = action.payload
      },
      prepare(orders) {
        return {
          payload: orders,
          meta: '',
          error: '',
        }
      },
    },
    updateFavorites: {
      reducer(state) {
        state.updaterFavorites = !state.updaterFavorites
      },
      prepare() {
        return {
          payload: '',
          meta: '',
          error: '',
        }
      },
    },
    changeSelectedAction: {
      reducer(state, action) {
        state.selected = action.payload
      },
      prepare(selected) {
        return {
          payload: selected,
          meta: '',
          error: '',
        }
      },
    },
    changeViewId: {
      reducer(state, action) {
        state.viewId = action.payload
      },
      prepare(viewId) {
        return {
          payload: viewId,
          meta: '',
          error: '',
        }
      },
    },
    updateMapAction: {
      reducer(state, action) {
        state.map = action.payload
      },
      prepare(map) {
        return {
          payload: map,
          meta: '',
          error: '',
        }
      },
    },
    updateOffsetAction: {
      reducer(state, action) {
        state.offset = action.payload
      },
      prepare(offset) {
        return {
          payload: offset,
          meta: '',
          error: '',
        }
      },
    },
    updateCoordinatesAction: {
      reducer(state, action) {
        state.coordinates = action.payload
      },
      prepare(coordinates) {
        return {
          payload: coordinates,
          meta: '',
          error: '',
        }
      },
    },
    updatePolygonAction: {
      reducer(state, action) {
        state.polygonCoordinates = action.payload
      },
      prepare(polygonCoordinates) {
        return {
          payload: polygonCoordinates,
          meta: '',
          error: '',
        }
      },
    },
    updateFocusProperty: {
      reducer(state, action) {
        state.focusPropertyId = action.payload
      },
      prepare(focusPropertyId) {
        return {
          payload: focusPropertyId,
          meta: '',
          error: '',
        }
      },
    },
  },
})
export const {
  updateSearcherHome,
  updateFavorites,
  reduxUpdateFilters,
  reduxUpdateOrderBy,
  changeSelectedAction,
  changeViewId,
  updateMapAction,
  updateOffsetAction,
  updateCoordinatesAction,
  updatePolygonAction,
  updateFocusProperty,
} = propertiesSlice.actions

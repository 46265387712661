import { ObjectTokkoFilters } from 'types.js'
import { originalTokkoApi } from '../originalTokkoApi'

import {
  getArrayOperationById,
  getOnlyArrayOperationById,
  getSumaryFilterDefault,
} from '@helpers/helper.filters'

interface Params {
  API_KEY?: string
  OPERATION?: string
  TYPE: any
  CTAG?: number[]
}

const propertyExtended = originalTokkoApi.injectEndpoints({
  endpoints: (builder) => ({
    getTotalFilters: builder.query<ObjectTokkoFilters, Params>({
      query: ({ API_KEY, OPERATION = '', TYPE = [], CTAG = [] }) => ({
        url: `property/get_search_summary/?key=${API_KEY}&lang=es_ar&format=json&data=${encodeURI(
          getSumaryFilterDefault({
            operation: getOnlyArrayOperationById(OPERATION),
            property_types: TYPE?.id ? TYPE?.id : [],
            with_custom_tags: CTAG,
          }),
        )}`,
        method: 'GET',
      }),
    }),
  }),
  overrideExisting: false,
})

export const { useGetTotalFiltersQuery } = propertyExtended

import { EventTracker } from 'types'
import { mediacoreApi } from '../mediacoreApi'
const trackerExtended = mediacoreApi.injectEndpoints({
  endpoints: (builder) => ({
    createApplication: builder.mutation({
      query: (body: EventTracker) => ({
        url: `${process.env.GATSBY_APISERVER}tracker/event/push/`,
        method: 'POST',
        body: body,
      }),
    }),
  }),
  overrideExisting: false,
})

export const { useCreateApplicationMutation } = trackerExtended

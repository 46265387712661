import { Action, EventTracker, RealEstateDataPage } from 'types'

export const getActionByPage = (actions: Action[], page: string) => {
  return actions.filter((action: Action) => action.section.toLowerCase() === page.toLowerCase())
}

export const getActionByType = (actions: any, type: string) => {
  if (actions) {
    return actions.filter((action: Action) => action.type.toLowerCase() === type.toLowerCase())
  }
  return []
}

export const convertStringToTime = (string: string) => {
  const date = string.split('T')[0]
  const splitDate = date.split('-')
  const formateDate = splitDate[2] + '-' + splitDate[1] + '-' + splitDate[0]
  return formateDate
}

export const removeExpiratedActions = (actions: Action[]) => {
  // const today = new Date()
  const filtered = []
  for (let index = 0; index < actions.length; index++) {
    const element = actions[index]
    // if(new Date(element.date_end) > today || element.date_end === null ){
    filtered.push(element)
    // }
  }
  return filtered
}

// SECCIONES:

// "INI", "Inicio"
// "EMP", "Emprendimientos"
// "FDE", "Ficha de emprendimiento"
// "FDP", "Ficha de la propiedad"
// "RVE", "Resultados Venta"
// "RAL", "Resultados Alquiler"
// "RAT", "Resultados Alquiler Temporario"
// "TAS", "Tasaciones"
// "NOV", "Novedades"
// "CON", "Contacto"
// "NOS", "Nosotros"

export const getCodes = (action: string, page: string) => {
  let code = ''
  switch (page) {
    case 'Inicio':
      code += 'INI_'
      break
    case 'Emprendimientos':
      code += 'EMP_'
      break
    case 'Ficha de emprendimiento':
      code += 'FDE_'
      break
    case 'Ficha de la propiedad':
      code += 'FDP_'
      break
    case 'Resultados Venta':
      code += 'RVE_'
      break
    case 'Resultados Alquiler':
      code += 'RAL_'
      break
    case 'Resultados Alquiler Temporario':
      code += 'RAT_'
      break
    case 'Tasaciones':
      code += 'TAS_'
      break
    case 'Novedades':
      code += 'NOV_'
      break
    case 'Contacto':
      code += 'CON_'
      break
    case 'Nosotros':
      code += 'NOS_'
      break
    default:
      code += page?.toUpperCase() + '_'
  }
  switch (action) {
    case 'Video Encabezado':
      code += 'VEN'
      break
    case 'Imagen Encabezado':
      code += 'IEN'
      break
    case 'Slider de Imagenes Encabezado':
      code += 'SIE'
      break
    case 'PopUp Cuadrado':
      code += 'PUC'
      break
    case 'PopUp Vertical':
      code += 'PUV'
      break
    case 'PopUp Panoramico':
      code += 'PUP'
      break
    case 'PopUp Timer':
      code += 'PUT'
      break
    case 'Barra Encabezado':
      code += 'BEN'
      break
    case 'Barra Encabezado Timer':
      code += 'BET'
      break
    case 'Solapa Flotante':
      code += 'SFL'
      break
    default:
      code += action
  }
  return code
}

export const getCodeBySection = (page: string) => {
  let code = ''
  switch (page) {
    case '/':
      code += 'INI'
      break
    case '/emprendimientos/':
      code += 'EMP'
      break
    case '/venta/':
      code += 'RVE'
      break
    case '/alquiler/':
      code += 'RAL'
      break
    case '/temporario/':
      code += 'RAT'
      break
    case '/tasaciones/':
      code += 'TAS'
      break
    case '/novedades/':
      code += 'NOV'
      break
    case '/contacto/':
      code += 'CON'
      break
    case '/quienes-somos/':
      code += 'NOS'
      break
    default:
      code += page?.toUpperCase() + '_'
  }
  return code
}

export const getCodeFromType = (action: string) => {
  let code = ''
  switch (action) {
    case 'Video Encabezado':
      code += 'VEN'
      break
    case 'Imagen Encabezado':
      code += 'IEN'
      break
    case 'Slider de Imagenes Encabezado':
      code += 'SIE'
      break
    case 'PopUp Cuadrado':
      code += 'PUC'
      break
    case 'PopUp Vertical':
      code += 'PUV'
      break
    case 'PopUp Panoramico':
      code += 'PUP'
      break
    case 'PopUp Timer':
      code += 'PUT'
      break
    case 'Barra Encabezado':
      code += 'BEN'
      break
    case 'Barra Encabezado Timer':
      code += 'BET'
      break
    case 'Solapa Flotante':
      code += 'SFL'
      break
    default:
      code += action
  }
  return code
}

export const getSectionFromCode = (page: string) => {
  let code = ''
  switch (page) {
    case 'INI':
      code += 'Inicio'
      break
    case 'EMP':
      code += 'Emprendimientos'
      break
    case 'FDE':
      code += 'Ficha de emprendimiento'
      break
    case 'FDP':
      code += 'Ficha de la propiedad'
      break
    case 'RVE':
      code += 'Resultados Venta'
      break
    case 'RAL':
      code += 'Resultados Alquiler'
      break
    case 'RAT':
      code += 'Resultados Alquiler Temporario'
      break
    case 'TAS':
      code += 'Tasaciones'
      break
    case 'NOV':
      code += 'Novedades'
      break
    case 'CON':
      code += 'Contacto'
      break
    case 'NOS':
      code += 'Nosotros'
      break
    default:
      code += page.toUpperCase() + '_'
  }
  return code
}

export const getTypeFromCode = (action: string) => {
  let code = ''
  switch (action) {
    case 'VEN':
      code += 'Video Encabezado'
      break
    case 'IEN':
      code += 'Imagen Encabezado'
      break
    case 'SIE':
      code += 'Slider de Imagenes Encabezado'
      break
    case 'PUC':
      code += 'PopUp Cuadrado'
      break
    case 'PUV':
      code += 'PopUp Vertical'
      break
    case 'PUP':
      code += 'PopUp Panoramico'
      break
    case 'PUT':
      code += 'PopUp Timer'
      break
    case 'BEN':
      code += 'Barra Encabezado'
      break
    case 'BET':
      code += 'Barra Encabezado Timer'
      break
    case 'SFL':
      code += 'Solapa Flotante'
      break
    default:
      code += action
  }
  return code
}

export const getQueryEventTracker = ({
  page,
  action,
  type,
  data_id,
  url,
  extra_title,
}: {
  page: RealEstateDataPage
  action: Action
  type: string
  data_id?: string
  url: string
  extra_title?: string
}) => {
  console.log(extra_title)
  const query: EventTracker = {
    sku: `${type}_${page.id}_${data_id}_${action.id}`,
    page_title: extra_title || page.title,
    action_name: action.name,
    action_type: getTypeFromCode(action.type),
    source_url: url,
  }
  return query
}

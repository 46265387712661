import React from 'react'
import { RealEstateDataHeaderData, RealEstateDataPage } from 'types'
import Boilerplate_Header_1 from '@boilerplates/header/1'
interface ExternalProps {
  pages: RealEstateDataPage[]
  data: RealEstateDataHeaderData
}

type CombinedProps = RealEstateDataHeaderData & ExternalProps

const Header3 = (props: CombinedProps) => {
  return (
    <Boilerplate_Header_1
      leftSide={true}
      {...props.data}
      hiddenLogo={true}
      {...props}
    />
  )
}

export default Header3

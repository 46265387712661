import { createSlice } from '@reduxjs/toolkit'
import { StateGallerySlice } from 'types'

export const gallerySlice = createSlice({
  name: 'gallery',
  initialState: {
    togglerVideos: false,
    togglerImages: false,
    togglerTours: false,
    togglerBlueprints: false,
    currentlyImage: 1,
    currentlyBluePrint: 1,
  } as StateGallerySlice,
  reducers: {
    setCurrentlyBlueprint: {
      reducer(state, action) {
        state.currentlyBluePrint = action.payload
      },
      prepare(currentlyBluePrint) {
        return {
          payload: currentlyBluePrint,
          meta: '',
          error: '',
        }
      },
    },
    setCurrentlyImage: {
      reducer(state, action) {
        state.currentlyImage = action.payload
      },
      prepare(currentlyImage) {
        return {
          payload: currentlyImage,
          meta: '',
          error: '',
        }
      },
    },
    setTogglerImages: {
      reducer(state) {
        state.togglerImages = !state.togglerImages
      },
      prepare() {
        return {
          payload: '',
          meta: '',
          error: '',
        }
      },
    },
    setTogglerVideos: {
      reducer(state) {
        state.togglerVideos = !state.togglerVideos
      },
      prepare() {
        return {
          payload: '',
          meta: '',
          error: '',
        }
      },
    },
    setTogglerTours: {
      reducer(state) {
        state.togglerTours = !state.togglerTours
      },
      prepare() {
        return {
          payload: '',
          meta: '',
          error: '',
        }
      },
    },
    setTogglerBlueprints: {
      reducer(state) {
        state.togglerBlueprints = !state.togglerBlueprints
      },
      prepare() {
        return {
          payload: '',
          meta: '',
          error: '',
        }
      },
    },
  },
})
export const {
  setCurrentlyBlueprint,
  setCurrentlyImage,
  setTogglerImages,
  setTogglerVideos,
  setTogglerTours,
  setTogglerBlueprints,
} = gallerySlice.actions
